import React from "react";
import { getAnswer } from ".";
import styles from "./index.module.scss";

export const FreeText = ({ question = {}, setAnswer = () => {} }) => {
  return (
    <div className={styles.freeText}>
      <textarea
        rows="5"
        data-cy-element="freeTextArea"
        value={getAnswer(question)}
        onChange={(e) => setAnswer(e?.target?.value, false)}
        placeholder="Please enter your response here."
      />
    </div>
  );
};

export default FreeText;
