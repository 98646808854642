import React from "react";

import FreeText from "./FreeText";
import NPS from "./Nps";
import OneToFiveStars from "./OneToFiveStars";
import YesNo from "./YesNo.js";
import { isEmpty } from "lodash";

import styles from "./index.module.scss";

export const wasQuestionSkipped = (q) =>
  q?.skipped || isEmpty(getAnswer(q)?.toString());

export const getAnswer = (question = {}) =>
  question[QUESTION_TYPE_ANSWER_KEY[question?.type]];

export const Question = ({ question = {}, setAnswer = () => {} }) => {
  const Answer = QUESTION_TYPE_COMPONENTS[question?.type];

  return (
    <>
      <div className={styles.surveyQuestionText}>{question?.text}</div>
      {Answer && <Answer question={question} setAnswer={setAnswer} />}
    </>
  );
};

export const QUESTION_TYPE = {
  freeText: "FREE_TEXT",
  yesno: "YES_NO",
  nps: "NPS",
  oneToFive: "ONE_TO_FIVE",
  rating: "RATING",
};

export const QUESTION_TYPE_COMPONENTS = {
  [QUESTION_TYPE.freeText]: FreeText,
  [QUESTION_TYPE.yesno]: YesNo,
  [QUESTION_TYPE.nps]: NPS,
  [QUESTION_TYPE.oneToFive]: OneToFiveStars,
};

export const QUESTION_TYPE_ANSWER_KEY = {
  [QUESTION_TYPE.freeText]: "answerText",
  [QUESTION_TYPE.yesno]: "answerNumber",
  [QUESTION_TYPE.nps]: "answerNumber",
  [QUESTION_TYPE.oneToFive]: "answerNumber",
};
