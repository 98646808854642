import React from "react";
import c from "classnames";
import { getAnswer } from ".";
import styles from "./index.module.scss";

export const OneToFiveStars = ({ question, setAnswer = () => {} }) => {
  const selectedStarValue = getAnswer(question);

  return (
    <div className={styles.oneToFiveStars} data-cy-element="oneToFive-wrapper">
      {[...Array(5).keys()].map((_, i) => {
        const starVal = i + 1,
          selected = starVal <= selectedStarValue;

        return (
          <span
            key={i}
            onClick={() => setAnswer(starVal)}
            data-cy-selected={selected}
            data-cy-element={`onetofive-${starVal}`}
          >
            <i
              className={c("fa", "fa-star", styles.oneToFiveStar, {
                [styles.oneToFiveStarSelected]: selected,
              })}
            />
          </span>
        );
      })}
    </div>
  );
};

export default OneToFiveStars;
