import React from "react";
import c from "classnames";
import { getAnswer } from ".";
import styles from "./index.module.scss";

import { Button } from "../../Survey";

export const YesNo = ({ question = {}, setAnswer = () => {} }) => {
  const answer = getAnswer(question),
    selectedIcon = <i className="fa fa-fw fa-solid fa-check" />,
    emptyIcon = <i className="fa fa-fw fa-blank" />;

  const YesNoButton = ({ label, value }) => {
    const isSelected = answer === value;

    return (
      <Button
        className={c(styles.yesNoButton, { isSelected })}
        primary={isSelected}
        secondary={!isSelected}
        onClick={() => setAnswer(value)}
        data-cy-element={`yesno-${value}`}
      >
        {isSelected ? selectedIcon : emptyIcon}
        {label}
      </Button>
    );
  };

  return (
    <div className={styles.yesNo}>
      <YesNoButton label="YES" value={1} />
      <YesNoButton label="NO" value={0} />
    </div>
  );
};

export default YesNo;
