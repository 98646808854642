import React from "react";
import c from "classnames";

import { getAnswer } from ".";
import styles from "./index.module.scss";

import { Button } from "../../Survey";

export const NPS = ({ question = {}, setAnswer = () => {} }) => {
  const answer = getAnswer(question);

  const npsButtonMapper = (npsVal) => {
    return (
      <Button
        key={npsVal}
        data-cy-element={`nps-${npsVal}`}
        primary={npsVal === answer}
        secondary={npsVal !== answer}
        onClick={() => setAnswer(npsVal)}
        className={c(styles.npsButton, {
          [styles.selectedNps]: npsVal === answer,
        })}
      >
        {npsVal}
      </Button>
    );
  };

  return (
    <div className={styles.nps}>
      <div className={styles.npsRatings}>
        {[...Array(6).keys()].map(npsButtonMapper)}
      </div>
      <div className={styles.npsRatings}>
        {[...Array(5).keys()].map((_, i) => npsButtonMapper(i + 6))}
      </div>
      <br />
      <div className={styles.npsLabels}>
        <span>0 = Not At All Likely</span>
        <span>10 = Extremely Likely</span>
      </div>
    </div>
  );
};

export default NPS;
